<template>
  <fragment>
    <!--    <div class="area-pasting-block">
&lt;!&ndash;      <div class="area-pasting-title">Выберите область брендирования</div>&ndash;&gt;
      <table class="area-pasting-cars">
        &lt;!&ndash;        <tr>
          <td :class="{ active: carPart === 'full' }" @click="carPart = 'full'">
            <img v-svg-inline src="@/assets/imgs/area-full.svg" alt="" />
          </td>
          <td :class="{ active: carPart === 'half' }" @click="carPart = 'half'">
            <img v-svg-inline src="@/assets/imgs/area-half.svg" alt="" />
          </td>
        </tr>&ndash;&gt;
        <tr>
          <td
            :class="{ active: carPart === 'two_thirds' }"
            @click="carPart = 'two_thirds'"
          >
            <img v-svg-inline src="@/assets/imgs/area-two-thirds.svg" alt="" />
          </td>
          <td
            :class="{ disable: !hiddenGlass, active: carPart === 'glass' }"
            @click="carPart = 'glass'"
          >
            <img v-svg-inline src="@/assets/imgs/area-glass.svg" alt="" />
          </td>
        </tr>
      </table>
    </div>-->

    <ul class="list_points" v-show="isVisible">
      <li class="list_point">
        <a>
          <toggle-button
            v-model="hiddenGlass"
            class="list-point__switcher"
            color="#ff8025"
            :sync="true"
          />
          Оклеивать стекло
        </a>
      </li>
    </ul>
  </fragment>
</template>

<script>
import { ToggleButton } from "vue-js-toggle-button";

export default {
  name: "Part",
  components: { ToggleButton },
  props: {
    carView: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    glass() {
      return this.carView.fObjs.find((o) => o.id === "glass");
    },
    hiddenGlass: {
      get() {
        return !this.glass.props.visible;
      },
      set(val) {
        this.glass.props.visible = !val;
        if (!val && this.carPart === "glass") {
          this.carPart = "full";
        }
      },
    },
    isVisible() {
      let result = false;
      this.carView?.fObjs?.forEach((o) => {
        if (o?.id === "glass" && o?.props?.url !== "https://api.cardis.tech") {
          result = true;
        }
      });
      return result;
    },
    carPart: {
      get() {
        return this.carView?.part;
      },
      set(val) {
        if (!this.hiddenGlass && val === "glass") return;
        this.carView.part = val;
      },
    },
  },
};
</script>
